<template>
  <div class="agent-card">
    <div class="agent-info">
      <div class="agent-step">
        <step/>
      </div>
      <div class="agent-invitation">
        <h5>邀請粉絲</h5>
        <p>邀请粉丝，粉絲首單立享 <strong>20% off</strong></p>
        <p>粉絲下單，您最高可得 <strong>{{infoData.topRate}}% 返現</strong></p>
        <p class="button-invitation">
          <button class="btn copy-btn"
                  :aria-label="shareLink"
                  @click="copyLink">複製連結，邀請粉絲
          </button>
        </p>
      </div>
      <div class="agent-info-hero">
        <h4>返現獎勵</h4>
        <template v-if="dataLoaded">
          <b>{{infoData.symbol}} {{infoData.amount}}</b>
          <p>历史累計獎勵 {{infoData.symbol}} {{infoData.maxCount}}</p>
          <p class="withdraw-action">
            <router-link to="/agent/record">
              <button class="btn default">返現記錄</button>
            </router-link>
            <button class="btn primary"
                    :disabled="!infoData.avaliableMoney"
                    @click="startWithdraw">前往提現
            </button>
          </p>
        </template>
        <div class="text-center" v-else>
          <div class="loading-icon"></div>
          <p class="mt-1">加载中...</p>
        </div>
      </div>
      <div class="agent-info-plane" v-if="dataLoaded">
        <router-link class="plane-item" to="/agent/fans">
          <div class="plane-item__icon">
            <img src="../../../static/images/profile/orders.svg" alt="">
          </div>
          <div class="plane-item__info">
            <p>我的粉絲</p>
            <b class="text-primary">{{infoData.fansCount}}人</b>
          </div>
        </router-link>
        <router-link class="plane-item" to="/agent/order">
          <div class="plane-item__icon">
            <img src="../../../static/images/profile/orders.svg" alt="">
          </div>
          <div class="plane-item__info">
            <p>可返現訂單</p>
            <b class="text-primary">{{infoData.rewardsCount}}單</b>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import Step from '../../components/layout/InvitationStep'
import Clipboard from 'clipboard'
import { mapActions } from 'vuex'

export default {
  name: '',
  components: { Step },
  data: () => ({
    infoData: {},
    dataLoaded: false
  }),
  computed: {
    shareLink () {
      return `https://ipastbook.com/?sourceId=${this.$store.state.user.uid}`
    }
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    ...mapActions(['setAvailableAmount']),
    fetchDetail () {
      api.fetchAgentInfo().then(data => {
        this.dataLoaded = true
        const {
          avaliableMoney,
          totalMoney,
          discount,
          topRate
        } = data
        this.infoData = {
          ...data,
          amount: avaliableMoney.toFixed(2),
          maxCount: totalMoney.toFixed(2),
          discount: Math.round(100 - discount * 100),
          topRate: Math.round(topRate * 100)
        }
        this.setAvailableAmount(this.infoData)
      })
    },
    startWithdraw () {
      this.$router.push({ name: 'agent-withdraw' })
    },
    copyLink (e) {
      console.log('click')
      if (window.isWkView && window.isAndroid) {
        this.nativeMessage({
          target: 'copy',
          message: this.shareLink
        })
        return
      }
      const clipboard = new Clipboard('.copy-btn', {
        text: function (trigger) {
          return trigger.getAttribute('aria-label')
        }
      })
      const vm = this
      const message = this.$t('invitation.tipCopyDone')
      clipboard.on('success', (e) => {
        vm.$toast({
          message
        })

        e.clearSelection()
      })
    }
  }
}
</script>

<style lang="scss">
.agent-info {
  &-hero {
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
    margin: 10px 12px;

    b {
      font-size: 32px;
      color: $el-color-primary;
    }
  }

  .agent-invitation {
    position: relative;
    height: 180px;
    border-radius: 6px;
    color: white;
    padding: 12px 16px;
    text-align: left;
    background: {
      color: #F56368;
      size: cover;
      position: center;
      image: url(../../../static/images/placeholder/agent-bg.png);
    };
    font-size: 13px;

    h5 {
      font-size: 16px;
    }

    strong {
      font-size: 18px;
      color: white;
    }

    p {
      &.button-invitation {
        text-align: center;
        margin-top: 1rem;

        .btn {
          color: white;
          border: 1px solid white;
        }
      }
    }
  }

  &-plane {
    display: flex;

    .plane-item {
      flex: 1;
      background-color: #fff;
      padding: 16px;
      border-radius: 6px;
      display: flex;
      font-size: 14px;
      text-align: left;
      border: 1px solid #f1f5e7;

      &:hover {
        color: currentColor;
      }

      &__icon {
        height: 40px;
        width: 40px;
        border: 1px solid #dddddd;
        border-radius: 100%;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .plane-item + .plane-item {
      margin-left: 14px;
    }
  }
}
</style>
